import { render, staticRenderFns } from "./CRMRetail.vue?vue&type=template&id=59c93519&scoped=true"
import script from "./CRMRetail.vue?vue&type=script&lang=js"
export * from "./CRMRetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59c93519",
  null
  
)

export default component.exports